// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-menu-js": () => import("./../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-templates-index-page-js": () => import("./../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-paginated-gallery-template-js": () => import("./../../src/templates/paginatedGalleryTemplate.js" /* webpackChunkName: "component---src-templates-paginated-gallery-template-js" */),
  "component---src-templates-postcard-template-js": () => import("./../../src/templates/postcardTemplate.js" /* webpackChunkName: "component---src-templates-postcard-template-js" */)
}

